<template>
  <div class="customer-record-container">
    <div class="record-list-tools">
      <el-form class="battery-form" :inline="true" :model="formData">
        <el-form-item label="设备编号">
          <!-- <el-input
            v-model="formData.sn"
            placeholder="设备编号"
            size="small"
            class="area-form-item"
            style="width: 180px"
            clearable
          >
          </el-input> -->
          <el-select v-model="formData.sn" filterable :style="{ width: '315px' }">
            <el-option
              v-for="item in deviceList"
              :key="item.id"
              :label="item.sn"
              :value="item.sn"
              
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="信息等级">
          <el-select
            v-model="formData.level"
            placeholder="信息等级"
            size="small"
            class="area-form-item"
          >
            <el-option label="全部" :value="null"></el-option>
            <el-option label="一般" :value="0"></el-option>
            <el-option label="紧急" :value="1"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="信息状态">
          <el-select
            v-model="formData.status"
            placeholder="信息状态"
            size="small"
            class="area-form-item"
          >
            <el-option label="全部" :value="null"></el-option>
            <el-option label="已处理" :value="1"></el-option>
            <el-option label="未处理" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="信息来源">
          <el-select
            v-model="formData.source"
            placeholder="信息来源"
            size="small"
            class="area-form-item"
          >
            <el-option v-for="(item,index) in sourceList" :key="index" :label="item.typeName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发生时间">
          <el-date-picker
            v-model="formData.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="timeChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="记录人">
          <el-input
            v-model="formData.firstPerson"
            placeholder="记录人"
            size="small"
            class="area-form-item"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="办结人">
          <el-input
            v-model="formData.lastPerson"
            placeholder="办结人"
            size="small"
            class="area-form-item"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="当前处理人">
          <el-input
            v-model="formData.currentPerson"
            placeholder="当前处理人"
            size="small"
            class="area-form-item"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="业务类型">
          <el-select
            v-model="formData.tagType"
            placeholder="业务类型"
            size="small"
            class="area-form-item"
          >
           <el-option
              v-for="(item, index) in readyData.tagList"
              :key="index"
              :label="item.typeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="float: right; marginright: 0px">
          <el-button type="primary" @click="getData()">搜索</el-button>
          <el-button @click="resetFrom()">重置</el-button>
        </el-form-item>
        <el-row>
          <el-form-item>
            <el-button type="primary" @click="addHandle()">新增</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <div class="record-table-container">
      <div class="record-table-content">
        <el-table
          style="width: 100%"
          height="100%"
          :data="list"
          v-loading="loading"
        >
          <!-- <el-table-column label="记录编号" prop="sn" width="140">
            <template slot-scope="scope">
              {{scope.row.code || '-'}}
            </template>
          </el-table-column> -->
          <el-table-column v-if="formData.organId === '99'" label="设备编号" prop="ompStatus">
            <template slot-scope="scope">
              {{ scope.row.pfsn || "-" }}
            </template>
          </el-table-column>
          <el-table-column v-else label="设备编号" prop="ompStatus">
            <template slot-scope="scope">
              {{ scope.row.sn || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="来源" prop="model">
            <template slot-scope="scope">
              {{scope.row.sourceText || '-'}}
            </template>
          </el-table-column>
          <el-table-column
            label="业务分类"
            prop="status"
          >
          <template slot-scope="scope">
              {{scope.row.tagTypeText || '-'}}
            </template>
          </el-table-column>
          <el-table-column
            label="业务子类"
            prop="ompStatus"
             width="200"
          >
          <template slot-scope="scope">
              {{scope.row.childTagTextList | childTagTextListStr}}
            </template>
          </el-table-column>
          <el-table-column
            label="发生时间"
            prop="ompStatus"
             width="200"
          >
          <template slot-scope="scope">
              {{scope.row.occurrenceTime || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="记录人" prop="sn">
            <template slot-scope="scope">
              {{scope.row.firstPersonText || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="当前处理人" prop="producer">
            <template slot-scope="scope">
              {{scope.row.currentPersonText || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="完结时间" prop="model"  width="200">
            <template slot-scope="scope">
              {{scope.row.lastTime || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="办结人" prop="status">
            <template slot-scope="scope">
              {{scope.row.lastPersonText || '-'}}
            </template>
          </el-table-column>
          <el-table-column
            label="当前状态"
            prop="ompStatus"
          >
          <template slot-scope="scope">
              {{scope.row.statusText || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="250">
            <template slot-scope="scope">
              <el-button @click="jumpDetailPage(scope.row, 1)" type="text" size="mini"
                >查看</el-button
              >
              <el-button @click="jumpDetailPage(scope.row, 2)" type="text" size="mini"
                >处理</el-button
              >
              <el-button v-if="scope.row.status === 0 && scope.row.flag === 0 && (!scope.row.questionType || (scope.row.questionType && scope.row.createOrder === 1))" @click="setHandle(scope.row)" type="text" size="mini"
                >转工单</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="record-table-page">
        <div class="record-table-page-container">
          <el-pagination
            background
            :current-page="formData.pageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="formData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <SetModel v-if="visable" ref="setModel" @refreshDataList="getData"/>
  </div>
</template>

<script>
import axios from "@/utils/axios";
import moment from "moment";
import SetModel from './components/SetupModel'
import "./Index.less";
export default {
  name: "CustomerRecord",
  data() {
    return {
      list: [],
      total: 0,
      loading: false,
      visable: false,
      sourceList: [],
      formData: {
        sn: null,
        level: null,
        status: null,
        source: null,
        startTime: null,
        endTime: null,
        time: null,
        firstPerson: null,
        lastPerson: null,
        organId: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        currentPerson: null,
        tagType: null
      },
      deviceList: [],
      readyData: {}
    };
  },
  components: { SetModel },
  mounted() {
    this.formData.organId = localStorage.getItem("organId");
    this.formData.sn = this.$route.params.sn;
    this.getData();
    this.getSourceList()
    this.getDeviceList()
    this.getTagList()
  },
  methods: {
    //获取来源列表
    getTagList() {
      axios
        .post(`/api/customerServiceCenter/ready`, {id: this.formData.organId})
        .then((res) => {
          this.readyData = res;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //获取列表数据
    getData() {
      this.loading = true;
      axios
        .post(`/api/customerServiceCenter/query`, this.formData)
        .then((res) => {
          const { list, total, page } = res;
          this.list = list;
          this.total = total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
    //获取当前机构下所有设备
    getDeviceList() {
       axios
        .post("/api/device/getDevices", { id: this.formData.organId })
        .then((rs) => {
          this.deviceList = rs;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //重置
    resetFrom() {
      this.formData = {
        sn: null,
        level: null,
        status: null,
        source: null,
        startTime: null,
        endTime: null,
        time: null,
        firstPerson: null,
        lastPerson: null,
        organId: localStorage.getItem("organId"),
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        currentPerson: null,
        tagType: null
      };
      this.getData();
    },
    //获取来源列表
    getSourceList() {
      axios
        .post(`/api/customerServiceCenter/source`, {id: this.formData.organId})
        .then((res) => {
          this.sourceList = res
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //时间选择
    timeChange(data) {
      if (data) {
        this.formData.startTime = this.DateToStr(data[0]);
        this.formData.endTime = this.DateToStr(data[1]);
      } else {
        this.formData.startTime = null;
        this.formData.endTime = null;
      }
    },
    //日期转字符串
    DateToStr(date) {
      var year = date.getFullYear(); //年
      var month = date.getMonth(); //月
      var day = date.getDate(); //日
      var hours = date.getHours(); //时
      var min = date.getMinutes(); //分
      var second = date.getSeconds(); //秒
      return (
        year +
        "-" +
        (month + 1 > 9 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (day > 9 ? day : "0" + day) +
        " " +
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (min > 9 ? min : "0" + min) +
        ":" +
        (second > 9 ? second : "0" + second)
      );
    },
    handleSizeChange(val) {
      this.formData.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.formData.pageIndex = val;
      this.getData();
    },
    //跳转详情
    addHandle() {
      let id = Math.round(Math.random()*10);
      let {href}= this.$router.resolve({
        path: "/add-customer",   // 这里写的是要跳转的路由地址
        query: {id: id}  // 这里写的是页面参数
      });
      window.open(href, '_blank');
    },
    jumpDetailPage(row, type) {
      let {href}= this.$router.resolve({
        path: "info-customer",   // 这里写的是要跳转的路由地址
        query: {
          id: row.id,
          type: type
        }  // 这里写的是页面参数
      });
      window.open(href, '_blank');
      // this.$router.push({
      //   name: "InfoCustomer",
      //   params: {
      //     id: row.id,
      //     type: type
      //   },
      // });
    },
    setHandle(item) {
      this.visable = true;
      let childProblem = item.childProblemTextList||[].toString()
      this.$nextTick(() => {
        this.$refs.setModel.init();
        this.$refs.setModel.title = item.code + " 工单处理";
        this.$refs.setModel.dataForm.sn = item.sn;
        this.$refs.setModel.dataForm.questionTypeText = item.questionTypeText;
        this.$refs.setModel.dataForm.childProblemTextList = childProblem
        this.$refs.setModel.dataForm.resultText = item.resultText
        this.$refs.setModel.dataForm.sourceId = item.id
      });
    }
  },
  filters: {
    childTagTextListStr(value) {
      let res = '-'
      if(value && value.length > 0) {
        res = value.join(',')
      }
      return res
    }
  }
};
</script>