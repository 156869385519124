<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="25%"
  >
    <div class="handle-content">
      <el-form
        ref="dataForm"
        :model="dataForm"
        :rules="rules"
        class="demo-form-inline"
      >
        <el-form-item
          label="人员选择"
          :label-width="'90px'"
          prop="nextPerson"
        >
          <el-select
            v-model="dataForm.nextPerson"
            placeholder="请选择"
            filterable
            :style="{ width: '315px' }"
          >
            <el-option
              v-for="(item, index) in persons"
              :key="index"
              :label="item.userName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="问题类型"
          :label-width="'90px'"
          prop="tagType"
        >
          <el-select
            v-model="dataForm.tagType"
            placeholder="问题类型"
            size="small"
            class="area-form-item"
            :style="{ width: '315px' }"
            @change="changeHandle"
          >
            <el-option
              v-for="(item, index) in readyData.questionTypeList"
              :key="index"
              :label="item.typeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="问题子类"
          :label-width="'90px'"
          prop="childTagArr"
        >
          <el-select
            v-model="dataForm.childTagArr"
            placeholder="问题子类"
            size="small"
            class="area-form-item"
            :style="{ width: '315px' }"
            multiple
            @change="subChange"
          >
            <el-option
              v-for="(item, index) in subList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="说明" :label-width="'90px'" prop="remark">
          <el-input v-model="dataForm.remark" placeholder="说明" :style="{ width: '315px' }" type="textarea">

          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <template slot="footer">
      <el-button type="primary" :loading="loading" @click="dataFormSubmitHandle()">确认</el-button>
      <el-button @click="visible = false">取消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import axios from "@/utils/axios";
export default {
  data() {
    return {
      title: null,
      loading: false,
      dataForm: {
        sn: null,
        nextPerson: null,
        remark: null,
        sourceId: null,
        organId: null,
        action: 1,
        suggest: 2,
        tagType: null,
        childTag: null,
        childTagArr: [],
        userId: null
      },
      visible: false,
      persons: null,
      readyData: {},
      subList: []
    };
  },
  computed: {
    rules() {
      return {
        omesType: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        nextPerson: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
      };
    },
  },
  methods: {
    //初始化
    init() {
      this.visible = true;
      this.dataForm.organId = localStorage.getItem("organId");
      this.dataForm.userId = localStorage.getItem('userId')
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        this.getPersonList();
        this.getSourceList()
      });
    },
    //获取故障处理人列表
    getPersonList() {
      axios
        .post("/api/cloudControl/persons", { id: this.dataForm.organId })
        .then((rs) => {
          this.persons = rs;
        });
    },
    //获取来源列表
    getSourceList() {
      axios
        .post(`/api/customerServiceCenter/ready`, {id: this.dataForm.organId})
        .then((res) => {
          this.readyData = res;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    changeHandle(value) {
      axios
        .post(`/api/customerServiceCenter/getChild`, { id: value })
        .then((res) => {
          this.subList = res;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    subChange(value) {
      this.dataForm.childTag = value.join(",");
    },
    dataFormSubmitHandle() {
      this.$refs["dataForm"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.loading = true
        axios
          .post(`/api/customerServiceCenter/toOrder`, this.dataForm)
          .then((res) => {
            if (res) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 500,
                onClose: () => {
                  this.visible = false;
                  this.$emit("refreshDataList");
                },
              });
              this.loading = false
            } else {
            }
          })
          .catch((err) => {
            this.loading = false
            return this.$message.error(err);
          });
      });
    },
  },
};
</script>